<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The distance from the Earth to the sun is
        <stemble-latex content="$93$" /> million miles. If light travels at
        <stemble-latex content="$3.00\,x\,10^8\,\text{m\s}$" />
        , how long does it take light to reach the Earth? (
        <stemble-latex content="$1 mile = 1.609 km$" />
        )
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{T}_\text{f}:$"
        append-text="$^{\circ}\text{C}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemCPP1210PQ1Q17',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
